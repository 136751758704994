<template>
  <div>
    <section class="section1 ">
      <div class="seccionlore1 backmorado container-fluid">
        <div class="row">
          <div class="col-9 pe-0">
            <p style="letter-spacing: -0.01em" class="titulo1 textomorado">
              El diseño editorial, una caja de sorpresas
            </p>
            <p class="texto2 textomorado pe-2">
              El diseño editorial es otra rama del diseño gráfico que me gusta mucho.
              Disfruto el distribuir y estructurar los elementos que componen una
              página de manera óptima y armoniosa, ya sea impresa o virtual en los
              diferentes tipos de publicaciones como las revistas, los libros, los
              flyers, los periódicos, los catálogos, entre otros.
            </p>
          </div>
          <div class="col-3 ps-0">
            <img src="../assets/imagenes/inicio/conchita.png" alt="Lapiz" class="conchita ">
          </div>
        </div>
      </div>
    </section>

    <!----------------------CONTENIDO----------------------->
    <!--------------------Fila 1----------------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-12 columna">
          <div>
            <img
                src="../assets/imagenes/de/agenda_quimichis.png"
                alt=""
                class="quimichis"
            />

          </div>
          <div class="text-center">
            <p
              class="texto4 textogris alignLeftCenter"
          >
            <b>Cliente:</b> QuimiChis <br/>
            <b>Lugar:</b> Madrid, España <br/>
            <b>Diseño:</b> Diseño editorial e ilustraciones
          </p></div>
        </div>
      </div>
    </div>

    <!----------------------Fila 2----------------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/de/libreta_azul.png"
                alt=""
                class="libreta"
            />
          </div>
        </div>

        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/de/libreta_anaranjada.png"
                alt=""
                class="libreta2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class=" columna">
      <p class="texto4 textogris text-center">
        Conoce y disfruta todos los diseños,
        <i style="color: red"><a class="btn btn-info " href="https://www.quimichis.com" target="_blank">visita a Quimichis</a> </i>
      </p>
    </div>

    <!----------------------Fila 3----------------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/de/libreta_morada.png"
                alt=""
                class="libretamorada"
            />
          </div>
        </div>

        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/de/libreta_rosa.png"
                alt=""
                class="libretarosa"
            />
          </div>
        </div>
      </div>
    </div>

    <!----------------------Fila 4----------------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-12 columna">
          <div>
            <img
                src="../assets/imagenes/de/libro_alegria_de_vivir.png"
                alt=""
                class="quimichis"
            />
            <p
                class="texto4 textogris alignLeftCenter"
            >
              <b>Cliente:</b> Avnatural<br/>
              <b>Lugar:</b> Madrid, España <br/>
              <b>Diseño:</b> Diseño editorial e ilustraciones
            </p>
          </div>
        </div>
      </div>
    </div>

    <!----------------------Fila 5----------------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/de/hay_otra_forma_de_vivir.png"
                alt=""
                class="libreta"
            />
          </div>
        </div>

        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/de/cuaderno_de_experiencias.png"
                alt=""
                class="cuaderno"
            />
          </div>
        </div>
      </div>
    </div>

    <!----------------------Fila 6----------------------->

    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-12 columna">
          
            <img
                src="../assets/imagenes/de/agenda_2006.png"
                alt=""
                class="agenda"
            />
            <p
                style="text-align: left; "
                class="textoagenda textogris"
            >
              <b>Cliente:</b> Universidad Loyola del Pacífico <br/>
              <b>Lugar:</b>Acapulco, México <br/>
              <b>Diseño:</b> Diseño editorial
            </p>
          
        </div>
      </div>
    </div>

    <!----------------------Fila 7----------------------->

    <div style="margin-bottom: 10%;" class="container-fluid contimg">
      <div class="row">
        <div class="col-12 columna">
          <div>
            <img
                src="../assets/imagenes/de/imprenta.png"
                alt=""
                class="imprenta"
            />
            <p
                class="texto4 textogris alignLeftCenter"
            >
              <b>Cliente:</b> Imprenta TYPESET<br/>
              <b>Lugar:</b> Monterrey, México <br/>
              <b>Diseño:</b> Diseño editorial de diferentes estilos de revistas
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiseñoEditorial",
  mounted() {
    this.$store.commit("SET_FOOTER", [true,'backmorado']);
  }
}
</script>

<style scoped>

</style>